class EnvService {
  constructor() {
    this.localDevEnv =
      process.env.VUE_APP_ENV === undefined ? "local" : process.env.VUE_APP_ENV;
    this.build = "1.0309";
    this.environments = [
      {
        env: "local",
        apiHost: "https://localhost",
        webbApp: "http://localhost:8080",
        cookieName: "ashore.app.local",
        stripeKey: "pk_test_D6ZhAI2nNZZegVcFOH5fYbej",
        loomKey: "9e5caf47-ed50-4d06-b7bc-443d44764d51",
        sentryDSN: "",
      },
      {
        env: "development",
        apiHost: "https://dev-api.ashoreapp.com",
        webbApp: "https://dev.ashoreapp.com",
        cookieName: "ashore.app.dev",
        stripeKey: "pk_test_D6ZhAI2nNZZegVcFOH5fYbej",
        loomKey: "9e5caf47-ed50-4d06-b7bc-443d44764d51",
        sentryDSN: "https://7224e372eb07a7b8a5688765cb3f4e4f@o4507221644607488.ingest.us.sentry.io/4507221653454848",
      },
      {
        env: "staging",
        // apiHost: "https://staging-api.ashoreapp.com",
        apiHost: "https://staging-api.ashoreapp.com",
        webbApp: "https://staging-sea.ashoreapp.com",
        cookieName: "ashore.app.staging",
        stripeKey: "pk_test_D6ZhAI2nNZZegVcFOH5fYbej",
        loomKey: "9e5caf47-ed50-4d06-b7bc-443d44764d51",
        sentryDSN: "https://7224e372eb07a7b8a5688765cb3f4e4f@o4507221644607488.ingest.us.sentry.io/4507221653454848",
      },
      {
        env: "production",
        apiHost: "https://api.ashoreapp.com",
        webbApp: "https://sea.ashoreapp.com",
        cookieName: "ashore.app.prod",
        stripeKey: "pk_live_a9Lt54FlBVLZ6160kGdj793B",
        loomKey: "9e5caf47-ed50-4d06-b7bc-443d44764d51",
        sentryDSN: "https://7224e372eb07a7b8a5688765cb3f4e4f@o4507221644607488.ingest.us.sentry.io/4507221653454848",
      },
    ];

    let curEnv =
      "production".indexOf("_") > 0 ? this.localDevEnv : "production";
    this.isLocalDev = "production".indexOf("_") > 0;
    this.env = this.environments.filter((e) => e.env === curEnv)[0];
    this.envName = this.env.env;
    this.apiHost = this.env.apiHost;
    this.webbApp = this.env.webbApp;
    this.cookieName = this.env.cookieName;
    this.stripeKey = this.env.stripeKey;
    this.loomKey = this.env.loomKey;
    this.sentryDSN = this.env.sentryDSN;
  }
}

EnvService.instance = new EnvService();
export default EnvService;
